.side_drawer {
  height: 300px;
  background: #F2F1F1;
  box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 215px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  border-bottom-right-radius: 25px;
}

.open {
  transform: translateX(0);
}

.close {
  color: #19545D;
  font-size: 24px;
  padding-bottom: 15px;
  cursor: pointer;
}

.side_drawer ul {
  height: 100%;
  display: flex;
  list-style: none;
  flex-direction: column;
  justify-content: flex-start;
  padding-inline-start: 30px;
}

.side_drawer li {
  margin: 0.5rem 0;
}

.side_drawer hr {
  border-top: 1px solid #76777B;
  width: 80%;
  margin-left: 0;
}

.side_drawer a {
  text-decoration: none;
  color: #76777B;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.side_drawer a:hover,
.side_drawer a:active {
  color: #fcba03;
}

.sidedrawer li {
  padding: 10px 0px;
  margin: 0 auto;
  font-variation-settings: 'wght' 500;
}

@media screen and (min-width: 360px) {
  .side_drawer {
    max-width: 240px;
  }
}

@media screen and (min-width: 1025px) {
  .side_drawer {
    display: none;
    max-width: 280px;
  }
}
