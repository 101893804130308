
.container-about{
  padding-top: 100px;
  margin: 0 auto;
  width:100%;
  background-color: #F6F9FA;
}

@media screen and (min-width:768px){
  .container-about{
    padding-top: 100px;
  }
}

.title-about {
  text-align: center;
  color: #58595B;
  padding-bottom: 30px;
  margin: 0 auto;
}

@media screen and (min-width:768px){
  .title-about{
  text-align: center;
  color: #58595B;
  padding-bottom: 80px;
  }
}


.title-about h1 {
  margin:0 auto;
  font-size: 18px;
}


@media screen and (min-width:768px) {
  .title-about h1 {
  margin: 0;
  font-size: 28px;
  }
}

.title-separator-about {
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
}

.title-separator-about hr {
  width: 5%;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #939598;
  opacity: 50%;
}

.fabric-about {
  width: 100%;
  margin: 0 auto;
  max-width:260px;
}



.fabric-about h6 {
  padding: 20px;
  font-size: 12px;
  position: relative;
  margin: 0 auto;
  font-weight:550;
  background-color: white;
  box-shadow: 30px 30px 15px -40px;
  border-radius: 25px;
}

@media screen and (min-width: 450px){
  .fabric-about {
    max-width: 330px;
  }
}

@media screen and (min-width:768px){
  .fabric-about h6 {
    padding: 60px;
    font-size: 18px;
  }
  .fabric-about {
    max-width: 600px;  
  }
}


@media screen and (min-width:1024px){
  .fabric-about{
    max-width:900px;
  }
}




.container-about .fabric-about img{
  width: 500px;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.container-about .fabric-about h6 span {
  color: #ffae3b;
  font-weight: 800;
}

.mission-about {
  padding-top:30px;
  padding-bottom: 150px;
}

@media screen and (min-width: 768px){
  .mission-about{
  padding-top: 100px;
  padding-bottom: 150px
  }
}

.cards {
  display:flex;
  flex-direction:row;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}

.card-mission {
  width: 100%;
  max-width: 850px;
  padding: 30px;
}

.cards p{
  font-size: 12px;
  font-weight: 600;
}

.card-vission {
  width: 100%;
  max-width: 850px;
  padding: 30px;
}


@media screen and (min-width: 768px){
  .cards p{
    font-size:18px;
  }

  .card-vission{
    max-width:450px;
  }

  .card-mission{
    max-width:450px;
  }
}

.cards img{
  max-width: 70px;
  max-height: 70px;
}

.down-image-container{
  display: flex;
  flex-direction: row;
  padding-top: 135px;
}

.about-logos img{
  max-width: 90%;
  padding:30px;
}

.about-cat img {
  max-width: 180px;
  max-height: 180px;
}