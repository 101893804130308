.navbar {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 5px 15px 0px rgba(204, 204, 204, 1);
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.navbar ul {
  list-style-type: none;
  display: none;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 30px;
  margin: 0px;
}

.navbar ul li {
  padding:0 30px;
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
}

.navbar a:link,
.navbar a:visited {
  color: black;
  text-decoration: none;
}

.logo {
  margin: 0 auto;
}

.logo img {
  width: 100%;
  max-width: 75px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 auto;
}

.icons img{
  max-height: 25px;
  padding: 0 7px;
}

.icons {
  display: none;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom:30px;
  padding-right: 50px;
}

.burger {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 35px;
}

.search {
  width: 50px;
}

@media only screen and (min-width: 768px) {
  .logo img {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .navbar ul {
    display: flex;
  }

  .icons {
    display: flex;
  }

  .logo {
    margin: unset;
  }

  .logo img {
    max-width: 125px;
    min-width: 75px;
    padding-left: 50px;
  }

  .navbar {
    padding-left: 100px;
    padding-right: 100px;
  }
}