.contact-container-wrapper {
  background: url('/img/contact-bg.jpg');
  height: 100%;
  width: 100%;
  background-size: cover;
  min-height: 800px;
  padding-top: 100px;
}

.contact-us-title {
  text-align: center;
  color: white;
}

.contact-us-title h1 {
  font-size: 28px;
  margin: 0;
}

.contact-us-title-separator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contact-us-title-separator img {
  width: 30px;
}

.contact-us-title-separator hr {
  width: 5%;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid white;
  opacity: 50%;
}

.contact-form-container {
  display: flex;
  height: 550px;
  width: 250px;
  flex-direction: column;
  padding: 20px;
  position: relative;
  margin: 0 auto;
  margin-top: 50px;
  background-color: white;
}

.contact-form-inputs {
  height: 70%;
  padding-bottom: 30px;
}

.contact-form-footer {
  height: 30%;
}

.contact-form-inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  width: 98.8%;
}

.contact-form-inputs > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact-form-inputs input,
.contact-form-inputs textarea {
  height: 80px;
  font-size: 18px;
  padding-left: 10px;
  width: 100%;
  border: 2px groove #BACBCD;
  font-weight: 700;
  font-family: unset;
}

.first-input-row-contact input {
  width: 47%;
}

.contact-form-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
}

.contact-form-footer-info p {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  color: #2B5E66;
}

.contact-form-footer-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.contact-form-submit input {
  background-color: #fcba03;
  border: none;
  border-radius: 5px;
  height: 50px;
  width: 70px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #124A53;
  cursor: pointer;
}

.contact-form-footer-info > div {
  display: flex;
  flex-direction: row;
  font-size: 14px;
}

.contact-form-footer-info > div img {
  max-height: 20px;
  max-width: 20px;
  width: auto;
  height: auto;
  padding-right: 10px;
}

@media only screen and (min-width: 450px) {
  .contact-container-wrapper {
    min-height: 900px;
  }

  .contact-form-container {
    height: 650px;
    width: 350px;
    padding: 30px;
  }

  .contact-form-submit input {
    height: 50px;
    width: 100px;
    font-size: 18px;
  }

  .contact-form-footer-info > div {
    font-size: unset;
  }
}

@media only screen and (min-width: 768px) {
  .contact-container-wrapper {
    min-height: 1000px;
  }

  .contact-form-container {
    height: 750px;
    width: 600px;
    padding: 50px;
  }

  .contact-form-submit input {
    height: 50px;
    width: 100px;
    font-size: 18px;
  }

  .contact-form-footer-info > div {
    font-size: unset;
  }
}

@media only screen and (min-width: 1024px) {
  .contact-form-container {
    height: 750px;
    width: 700px;
    padding: 50px;
  }

  .contact-form-submit input {
    height: 50px;
    width: 100px;
    font-size: 18px;
  }

  .contact-form-footer-info > div {
    font-size: unset;
  }
}