.layout-container{
  height:100%;
  display:flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.content-home {
  flex-grow: 1;
  width: 100%;
}