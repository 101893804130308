.finder {
  width: 100%;
  height: 700px;
  background-image: url('/img/home-secondary.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  color: #58595B;
}

.title h1 {
  font-size: 18px;
  margin-bottom: 5px;
}

.title-separator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.title-separator hr {
  width: 10%;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #939598;
  opacity: 50%;
}

.search-options {
  height: 40vh;
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.search-options > div {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-options > div img {
  width: 75%;
  padding-bottom: 25px;
}

.search-options .perro-adulto img {
  width: 120%;
}

.search-options > div a {
  width: 75%;
}

.search-options > div a:visited,
.search-options > div a:link {
  text-decoration: none;
}

.search-options > div div {
  background-color: #0A4A54;
  color: white;
  border-radius: 30px;
  padding: 15px 0px;
  width: 140%;
  margin-left: -12px;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  box-shadow:25px 25px 30px 0px rgba(0,0,0,0.75);
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .search-options {
    width: 50vw;
  }

  .title h1 {
    font-size: 24px;
  }

  .search-options > div div {
    width: 120%;
    margin-left: -6px;
    font-size: 12px;
  }

  .finder {
    height: 1000px;
  }
}

@media only screen and (min-width: 1024px) {
  .search-options > div div {
    width: 100%;
    margin-left: 0px;
    font-size: 14px;
  }

  .title h1 {
    font-size: 28px;
  }
}