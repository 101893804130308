.carousel {
  position: relative;
}

.responsive-buttons {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  max-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.responsive-btn {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  background-color: #447E87;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.responsive-btn a:link,
.responsive-btn a:visited {
  text-align: center;
}

.responsive-btn img {
  margin-top: 3px;
  max-width: 40px;
  max-height: 40px;
}

@media screen and (min-width: 992px) {
  .responsive-buttons {
    display: none;
  }
}

/*
*********************
*****  SLIDE 1  *****
*********************
*/

.slide-1 {
  width: 100%;
  height: 600px;
  background-image: url('/img/home-banner.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  position: relative;
}

.slide-1 h1 {
  text-transform: uppercase;
  text-shadow: 18px 18px 12px #959595;
  color: #fff;
  font-weight: 900;
  font-size: 32px;
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
  padding-top: 55px;
  margin-bottom: 10px;
}

.slide-1 h4 {
  margin-top: 10px;
  text-shadow: 18px 18px 12px #959595;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  color: #fff;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .slide-1 {
    height: 700px;
  }

  .slide-1 h1 {
    font-size: 64px;
    max-width: 600px;
  }

  .slide-1 h4 {
    max-width: 900px;
  }
}

/*
*********************
*****  SLIDE 2  *****
*********************
*/

.slide-2 {
  width: 100%;
  height: 600px;
  background-image: url('/img/fondo_delipet.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  position: relative;
}

.slide-2-dogs-img {
  position: absolute;
  bottom: 100px;
  left: 2%;
}

.slide-2-dogs-img img {
  max-height: 200px;
  max-width: 200px;
}

.slide-2-superrico-img {
  position: absolute;
  top: 300px;
  left: 2%;
}

.slide-2-superrico-img img {
  max-height: 80px;
  max-width: 80px;
}

.slide-2-delipet-img {
  position: absolute;
  top: 80px;
  right: 30%;
}

.slide-2-delipet-img img {
  max-height: 80px;
  max-width: 80px;
}

.slide-2-delipet-food-img {
  position: absolute;
  bottom: 100px;
  right: 5%;
}

.slide-2-delipet-food-img img {
  max-height: 150px;
  max-width: 150px;
}

.slide-2-delipet-txt {
  position: absolute;
  top: 170px;
  right: 9%;
  max-width: 160px;
}

.slide-2-delipet-txt p {
  font-size: 14px;
  font-weight: 600;
  color: #282020;
  text-shadow: 18px 18px 12px #959595;
}

.slide-2-ver-mas {
  position: absolute;
  top: 315px;
  right: 9%;
  padding: 10px;
  background-color: #fcba03;
  border-radius: 35px;
  font-size: 18px;
  box-shadow: 20px 20px 12px #dedede;
}

.slide-2-ver-mas a:link,
.slide-2-ver-mas a:visited {
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
}

@media screen and (min-width: 630px) {
  .slide-2-dogs-img img {
    max-height: 350px;
    max-width: 350px;
  }

  .slide-2-superrico-img img {
    max-height: 100px;
    max-width: 100px;
  }

  .slide-2-delipet-txt {
    position: absolute;
    top: 170px;
    right: 16%;
    max-width: 180px;
  }

  .slide-2-delipet-img {
    position: absolute;
    top: 70px;
    right: 28%;
  }

  .slide-2-delipet-img img {
    max-height: 100px;
    max-width: 100px;
  }

  .slide-2-delipet-food-img {
    position: absolute;
    bottom: 50px;
    right: 5%;
  }

  .slide-2-delipet-food-img img {
    max-height: 250px;
    max-width: 250px;
  }
}

@media screen and (min-width: 768px) {
  .slide-2 {
    height: 700px;
  }

  .slide-2-dogs-img {
    position: absolute;
    bottom: 100px;
    left: 5%;
  }
  
  .slide-2-dogs-img img {
    max-height: 425px;
    max-width: 425px;
  }
  
  .slide-2-superrico-img {
    position: absolute;
    top: 200px;
    left: 3%;
  }
  
  .slide-2-superrico-img img {
    max-height: 180px;
    max-width: 180px;
  }

  .slide-2-delipet-img {
    position: absolute;
    top: 50px;
    right: 30%;
  }
  
  .slide-2-delipet-img img {
    max-height: 100px;
    max-width: 100px;
  }
  
  .slide-2-delipet-food-img {
    position: absolute;
    bottom: 100px;
    right: 3%;
  }

  .slide-2-delipet-food-img img {
    max-height: 300px;
    max-width: 300px;
  }
  
  .slide-2-delipet-txt {
    position: absolute;
    top: 150px;
    right: 14%;
    max-width: 225px;
  }

  .slide-2-ver-mas {
    position: absolute;
    top: 315px;
    right: 9%;
    padding: 10px;
    background-color: #fcba03;
    border-radius: 35px;
    font-size: 18px;
    box-shadow: 20px 20px 12px #dedede;
  }
}

@media screen and (min-width: 992px) {
  .slide-2-dogs-img {
    position: absolute;
    bottom: 100px;
    left: 5%;
  }
  
  .slide-2-dogs-img img {
    max-height: 500px;
    max-width: 500px;
  }
  
  .slide-2-superrico-img {
    position: absolute;
    top: 125px;
    left: 4%;
  }
  
  .slide-2-superrico-img img {
    max-height: 200px;
    max-width: 200px;
  }

  .slide-2-delipet-img {
    position: absolute;
    top: 50px;
    right: 30%;
  }
  
  .slide-2-delipet-img img {
    max-height: 100px;
    max-width: 100px;
  }
  
  .slide-2-delipet-food-img {
    position: absolute;
    bottom: 100px;
    right: 5%;
  }

  .slide-2-delipet-food-img img {
    max-height: 350px;
    max-width: 350px;
  }
  
  .slide-2-delipet-txt {
    position: absolute;
    top: 150px;
    right: 6%;
  }

  .slide-2-delipet-txt p {
    font-size: 22px;
  }

  .slide-2-ver-mas {
    position: absolute;
    top: 260px;
    right: 28%;
    padding: 15px;
    background-color: #fcba03;
    border-radius: 35px;
    font-size: 21px;
    box-shadow: 20px 20px 12px #dedede;
  }
}

@media screen and (min-width: 1120px) {
  .slide-2-dogs-img {
    position: absolute;
    bottom: 100px;
    left: 5%;
  }
  
  .slide-2-dogs-img img {
    max-height: 600px;
    max-width: 600px;
  }
  
  .slide-2-superrico-img {
    position: absolute;
    top: 125px;
    left: 5%;
  }
  
  .slide-2-superrico-img img {
    max-height: 200px;
    max-width: 200px;
  }

  .slide-2-delipet-img {
    position: absolute;
    top: 50px;
    right: 30%;
  }
  
  .slide-2-delipet-img img {
    max-height: 100px;
    max-width: 100px;
  }
  
  .slide-2-delipet-food-img {
    position: absolute;
    bottom: 0px;
    right: 5%;
  }

  .slide-2-delipet-food-img img {
    max-height: 450px;
    max-width: 450px;
  }
  
  .slide-2-delipet-txt {
    position: absolute;
    top: 150px;
    right: 10%;
  }

  .slide-2-ver-mas {
    position: absolute;
    top: 260px;
    right: 28%;
    padding: 15px;
    background-color: #fcba03;
    border-radius: 35px;
    font-size: 21px;
    box-shadow: 20px 20px 12px #dedede;
  }
}

@media screen and (min-width: 1400px) {
  .slide-2-dogs-img {
    position: absolute;
    bottom: 0;
    left: 13%;
  }
  
  .slide-2-dogs-img img {
    max-height: 700px;
    max-width: 700px;
  }
  
  .slide-2-superrico-img {
    position: absolute;
    top: 125px;
    left: 11%;
  }
  
  .slide-2-superrico-img img {
    max-height: 250px;
    max-width: 250px;
  }

  .slide-2-delipet-img {
    position: absolute;
    top: 50px;
    right: 30%;
  }
  
  .slide-2-delipet-img img {
    max-height: 100px;
    max-width: 100px;
  }
  
  .slide-2-delipet-food-img {
    position: absolute;
    bottom: 0px;
    right: 5%;
  }
  
  .slide-2-delipet-txt {
    position: absolute;
    top: 150px;
    right: 16%;
  }

  .slide-2-ver-mas {
    position: absolute;
    top: 260px;
    right: 30%;
    padding: 15px;
    background-color: #fcba03;
    border-radius: 35px;
    font-size: 21px;
    box-shadow: 20px 20px 12px #dedede;
  }
}

/*
*********************
*****  SLIDE 3  *****
*********************
*/

.slide-3 {
  width: 100%;
  height: 600px;
  background-image: url('/img/fondo_canbuffet.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  position: relative;
}

.slide-3-can-buffet-dogs-img {
  position: absolute;
  bottom: 50px;
  left: 10%;
}

.slide-3-can-buffet-dogs-img img {
  max-height: 280px;
  max-width: 280px;
}

.slide-3-can-buffet-img {
  position: absolute;
  top: 50px;
  right: 15%;
}

.slide-3-can-buffet-img img {
  max-width: 150px;
  max-height: 150px;
}

.slide-3-can-buffet-text {
  position: absolute;
  top: 130px;
  right: 16%;
  max-width: 150px;
}

.slide-3-can-buffet-text p {
  font-size: 18px;
  font-weight: 600;
  color: #282020;
  text-shadow: 18px 18px 12px #959595;
}

.slide-3-can-buffet-food-img {
  position: absolute;
  bottom: 50px;
  right: 10%;
}

.slide-3-can-buffet-food-img img {
  max-width: 150px;
  max-height: 150px;
}

.slide-3-can-buffet-btn {
  position: absolute;
  top: 300px;
  right: 15%;
  padding: 10px;
  background-color: #fcba03;
  border-radius: 35px;
  font-size: 18px;
  box-shadow: 20px 20px 12px #dedede;
}

.slide-3-can-buffet-btn a:link,
.slide-3-can-buffet-btn a:visited {
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
}

@media screen and (min-width: 630px) {
  .slide-3-can-buffet-dogs-img img {
    max-height: 450px;
    max-width: 450px;
  }
}

@media screen and (min-width: 992px) {
  .slide-3 {
    width: 100%;
    height: 700px;
    background-image: url('/img/fondo_canbuffet.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
    position: relative;
  }
  
  .slide-3-can-buffet-dogs-img {
    position: absolute;
    bottom: 130px;
    left: 10%;
  }
  
  .slide-3-can-buffet-dogs-img img {
    max-height: 600px;
    max-width: 600px;
  }
  
  .slide-3-can-buffet-img {
    position: absolute;
    top: 50px;
    right: 25%;
  }
  
  .slide-3-can-buffet-img img {
    max-width: 200px;
    max-height: 200px;
  }
  
  .slide-3-can-buffet-text {
    position: absolute;
    top: 140px;
    right: 10%;
    max-width: 500px;
  }
  
  .slide-3-can-buffet-text p {
    font-size: 22px;
    font-weight: 600;
    color: #282020;
    text-shadow: 18px 18px 12px #959595;
  }
  
  .slide-3-can-buffet-food-img {
    position: absolute;
    bottom: 150px;
    right: 10%;
  }
  
  .slide-3-can-buffet-food-img img {
    max-width: 180px;
    max-height: 240px;
  }
  
  .slide-3-can-buffet-btn {
    position: absolute;
    top: 260px;
    right: 27%;
    padding: 15px;
    background-color: #fcba03;
    border-radius: 35px;
    font-size: 28px;
    box-shadow: 20px 20px 12px #dedede;
  }
  
  .slide-3-can-buffet-btn a:link,
  .slide-3-can-buffet-btn a:visited {
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
  }
}

@media screen and (min-width: 1120px) {
  .slide-3 {
    width: 100%;
    height: 700px;
    background-image: url('/img/fondo_canbuffet.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
    position: relative;
  }
  
  .slide-3-can-buffet-dogs-img {
    position: absolute;
    bottom: 30px;
    left: 10%;
  }
  
  .slide-3-can-buffet-dogs-img img {
    max-height: 800px;
    max-width: 780px;
  }
  
  .slide-3-can-buffet-img {
    position: absolute;
    top: 50px;
    right: 25%;
  }
  
  .slide-3-can-buffet-img img {
    max-width: 200px;
    max-height: 200px;
  }
  
  .slide-3-can-buffet-text {
    position: absolute;
    top: 140px;
    right: 13%;
  }
  
  .slide-3-can-buffet-text p {
    font-size: 22px;
    font-weight: 600;
    color: #282020;
    text-shadow: 18px 18px 12px #959595;
  }
  
  .slide-3-can-buffet-food-img {
    position: absolute;
    bottom: 50px;
    right: 10%;
  }
  
  .slide-3-can-buffet-food-img img {
    max-width: 240px;
    max-height: 300px;
  }
  
  .slide-3-can-buffet-btn {
    position: absolute;
    top: 260px;
    right: 27%;
    padding: 15px;
    background-color: #fcba03;
    border-radius: 35px;
    font-size: 28px;
    box-shadow: 20px 20px 12px #dedede;
  }
  
  .slide-3-can-buffet-btn a:link,
  .slide-3-can-buffet-btn a:visited {
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
  }
}

@media screen and (min-width: 1400px) {
  .slide-3 {
    width: 100%;
    height: 700px;
    background-image: url('/img/fondo_canbuffet.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
    position: relative;
  }
  
  .slide-3-can-buffet-dogs-img {
    position: absolute;
    bottom: 30px;
    left: 10%;
  }
  
  .slide-3-can-buffet-dogs-img img {
    max-height: 700px;
    max-width: 1200px;
  }
  
  .slide-3-can-buffet-img {
    position: absolute;
    top: 50px;
    right: 25%;
  }
  
  .slide-3-can-buffet-img img {
    max-width: 200px;
    max-height: 200px;
  }
  
  .slide-3-can-buffet-text {
    position: absolute;
    top: 140px;
    right: 16%;
  }
  
  .slide-3-can-buffet-text p {
    font-size: 22px;
    font-weight: 600;
    color: #282020;
    text-shadow: 18px 18px 12px #959595;
  }
  
  .slide-3-can-buffet-food-img {
    position: absolute;
    bottom: 50px;
    right: 10%;
  }
  
  .slide-3-can-buffet-food-img img {
    max-width: 300px;
    max-height: 400px;
  }
  
  .slide-3-can-buffet-btn {
    position: absolute;
    top: 260px;
    right: 27%;
    padding: 15px;
    background-color: #fcba03;
    border-radius: 35px;
    font-size: 28px;
    box-shadow: 20px 20px 12px #dedede;
  }
  
  .slide-3-can-buffet-btn a:link,
  .slide-3-can-buffet-btn a:visited {
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
  }
}

/*
*********************
*****  SLIDE 4  *****
*********************
*/

.slide-4 {
  width: 100%;
  height: 600px;
  background-image: url('/img/fondo_yum.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  position: relative;
}

.slide-4-yum-dog-img {
  position: absolute;
  bottom: 50px;
  left: 0%;
}

.slide-4-yum-dog-img img {
  max-height: 300px;
  max-width: 300px;
}

.slide-4-yum-img {
  position: absolute;
  top: 50px;
  right: 20%;
}

.slide-4-yum-img img {
  max-height: 175px;
  max-width: 175px;
}

.slide-4-yum-text {
  position: absolute;
  top: 175px;
  right: 10%;
}

.slide-4-yum-text p {
  font-size: 16px;
  font-weight: 600;
  color: #282020;
  text-shadow: 18px 18px 12px #959595;
  max-width: 200px;
}

.slide-4-yum-btn {
  position: absolute;
  top: 300px;
  right: 15%;
  padding: 10px;
  background-color: #fcba03;
  border-radius: 35px;
  font-size: 18px;
  box-shadow: 20px 20px 12px #dedede;
}

.slide-4-yum-btn a:link,
.slide-4-yum-btn a:visited {
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
}

.slide-4-yum-food-img {
  position: absolute;
  bottom: 50px;
  right: 5%;
}

.slide-4-yum-food-img img {
  max-height: 180px;
  max-width: 180px;
}

@media screen and (min-width: 630px) {
  .slide-4-yum-dog-img img {
    max-height: 300px;
    max-width: 500px;
  }
}

@media screen and (min-width: 992px) {
  .slide-4 {
    width: 100%;
    height: 700px;
    background-image: url('/img/fondo_yum.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
    position: relative;
  }
  
  .slide-4-yum-dog-img {
    position: absolute;
    bottom: 15px;
    left: 3%;
  }
  
  .slide-4-yum-dog-img img {
    max-height: 700px;
    max-width: 700px;
  }
  
  .slide-4-yum-img {
    position: absolute;
    top: 50px;
    right: 25%;
  }
  
  .slide-4-yum-img img {
    max-height: 225px;
    max-width: 225px;
  }
  
  .slide-4-yum-text {
    position: absolute;
    top: 175px;
    right: 5%;
  }
  
  .slide-4-yum-text p {
    font-size: 22px;
    font-weight: 600;
    color: #282020;
    text-shadow: 18px 18px 12px #959595;
    max-width: 500px;
  }
  
  .slide-4-yum-btn {
    position: absolute;
    top: 300px;
    right: 28%;
    padding: 15px;
    background-color: #fcba03;
    border-radius: 35px;
    font-size: 28px;
    box-shadow: 20px 20px 12px #dedede;
  }
  
  .slide-4-yum-btn a:link,
  .slide-4-yum-btn a:visited {
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
  }
  
  .slide-4-yum-food-img {
    position: absolute;
    bottom: 20px;
    right: 10%;
  }
  
  .slide-4-yum-food-img img {
    max-height: 350px;
    max-width: 350px;
  }
}

@media screen and (min-width: 1120px) {
  .slide-4 {
    width: 100%;
    height: 700px;
    background-image: url('/img/fondo_yum.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
    position: relative;
  }
  
  .slide-4-yum-dog-img {
    position: absolute;
    bottom: 15px;
    left: 3%;
  }
  
  .slide-4-yum-dog-img img {
    max-height: 700px;
    max-width: 800px;
  }
  
  .slide-4-yum-img {
    position: absolute;
    top: 50px;
    right: 25%;
  }
  
  .slide-4-yum-img img {
    max-height: 225px;
    max-width: 225px;
  }
  
  .slide-4-yum-text {
    position: absolute;
    top: 175px;
    right: 8%;
  }
  
  .slide-4-yum-text p {
    font-size: 22px;
    font-weight: 600;
    color: #282020;
    text-shadow: 18px 18px 12px #959595;
  }
  
  .slide-4-yum-btn {
    position: absolute;
    top: 300px;
    right: 28%;
    padding: 15px;
    background-color: #fcba03;
    border-radius: 35px;
    font-size: 28px;
    box-shadow: 20px 20px 12px #dedede;
  }
  
  .slide-4-yum-btn a:link,
  .slide-4-yum-btn a:visited {
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
  }
  
  .slide-4-yum-food-img {
    position: absolute;
    bottom: 20px;
    right: 10%;
  }
  
  .slide-4-yum-food-img img {
    max-height: 350px;
    max-width: 350px;
  }
}

@media screen and (min-width: 1400px) {
  .slide-4 {
    width: 100%;
    height: 700px;
    background-image: url('/img/fondo_yum.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
    position: relative;
  }
  
  .slide-4-yum-dog-img {
    position: absolute;
    bottom: 15px;
    left: 3%;
  }
  
  .slide-4-yum-dog-img img {
    max-height: 700px;
    max-width: 1000px;
  }
  
  .slide-4-yum-img {
    position: absolute;
    top: 50px;
    right: 25%;
  }
  
  .slide-4-yum-img img {
    max-height: 225px;
    max-width: 225px;
  }
  
  .slide-4-yum-text {
    position: absolute;
    top: 175px;
    right: 10%;
  }
  
  .slide-4-yum-text p {
    font-size: 22px;
    font-weight: 600;
    color: #282020;
    text-shadow: 18px 18px 12px #959595;
  }
  
  .slide-4-yum-btn {
    position: absolute;
    top: 300px;
    right: 28%;
    padding: 15px;
    background-color: #fcba03;
    border-radius: 35px;
    font-size: 28px;
    box-shadow: 20px 20px 12px #dedede;
  }
  
  .slide-4-yum-btn a:link,
  .slide-4-yum-btn a:visited {
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
  }
  
  .slide-4-yum-food-img {
    position: absolute;
    bottom: 20px;
    right: 10%;
  }
  
  .slide-4-yum-food-img img {
    max-height: 350px;
    max-width: 350px;
  }
}

@media screen and (min-width: 1700px) {
  .slide-4 {
    width: 100%;
    height: 700px;
    background-image: url('/img/fondo_yum.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
    position: relative;
  }
  
  .slide-4-yum-dog-img {
    position: absolute;
    bottom: 15px;
    left: 3%;
  }
  
  .slide-4-yum-dog-img img {
    max-height: 700px;
    max-width: 1200px;
  }
  
  .slide-4-yum-img {
    position: absolute;
    top: 50px;
    right: 25%;
  }
  
  .slide-4-yum-img img {
    max-height: 225px;
    max-width: 225px;
  }
  
  .slide-4-yum-text {
    position: absolute;
    top: 175px;
    right: 12%;
  }
  
  .slide-4-yum-text p {
    font-size: 22px;
    font-weight: 600;
    color: #282020;
    text-shadow: 18px 18px 12px #959595;
  }
  
  .slide-4-yum-btn {
    position: absolute;
    top: 300px;
    right: 28%;
    padding: 15px;
    background-color: #fcba03;
    border-radius: 35px;
    font-size: 28px;
    box-shadow: 20px 20px 12px #dedede;
  }
  
  .slide-4-yum-btn a:link,
  .slide-4-yum-btn a:visited {
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
  }
  
  .slide-4-yum-food-img {
    position: absolute;
    bottom: 20px;
    right: 10%;
  }
  
  .slide-4-yum-food-img img {
    max-height: 350px;
    max-width: 350px;
  }
}
