.brands-container {
  background-image: url('/img/bg-our-brands.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 190vh 137vw;
  min-height: 100%;
  padding-top: 100px;
}

.brands-title {
  text-align: center;
  color: #58595B;
}

.brands-title h1 {
  font-size: 28px;
  margin: 0;
}

.brands-title-separator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.brands-title-separator hr {
  width: 5%;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #939598;
  opacity: 50%;
}

.brands-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 100px;
}

.brands-filter-container h1 {
  font-size: 20px;
}

.brands-filter-container > h1 {
  font-weight: 600;
}

.brands-filter-container {
  min-width: 40%;
}

.brands-filter {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 220px;
  right: 0;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: #F2F2F3;
  padding-left: 25px;
  z-index: 5;
}

.brands-product-display {
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
}

.filter-by-brand div,
.filter-by-brand h1 {
  border-bottom: 1px solid #fcba03;
  width: 70%;
  padding: 8px 0px;
  font-size: 20px;
}

.filter-by-category div,
.filter-by-category h1 {
  border-bottom: 1px solid #0A4A54;
  width: 70%;
  padding: 8px 0px;
  font-size: 20px;
}

.filter-by-amount div,
.filter-by-amount h1 {
  border-bottom: 1px solid red;
  width: 70%;
  padding: 8px 0px;
  font-size: 20px;
}

.brands-filter-container > div,
.brands-filter-container > h1 {
  padding-bottom: 25px;
}

.product-box {
  width: 50px;
  height:200px;
  background-color: white;
  padding: 25px 75px;
  box-shadow: 0px 0px 10px -7px rgba(0,0,0,0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 50px;
}

.product-box p {
  width: 200px;
  margin-bottom: 0;
}

.brands-product-display a:visited,
.brands-product-display a:link {
  text-decoration: none;
  color: #58595B;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
}

.brands-product-display a p {
  opacity: 80%;
  text-align: center;
}

.brands-product-display a p span {
  text-transform: lowercase;
}

.product-box img {
  max-height: 150px;
}

.filter-by-brand > div,
.filter-by-category > div,
.filter-by-amount > div {
  cursor: pointer;
}

.filter-by-brand .clicked,
.filter-by-category .clicked,
.filter-by-amount .clicked {
  font-weight: 600;
}

.brands-filter-container .delete-filters-brands {
  font-size: 20px;
  color: #fcba03;
  padding-bottom: 10px;
  cursor: pointer;
}

.open-filters-button {
  background-color: #FFFFFF;
  width: 120%;
  margin-left: -20%;
  text-align: center;
  font-size: 24px;
  color: #fcba03;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.open-filters-button > div {
  border: 1px solid #fcba03;
  width: 50%;
  border-radius: 10px;
}

.brands-filter .close-filter-options {
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 0;
  font-size: 24px;
  color: #fcba03;
}

@media only screen and (min-width: 768px) {
  .product-box {
    width: 200px;
    height:400px;
    padding: 25px 75px;
    margin-bottom: 50px;
  }

  .product-box p {
    width:300px;
    margin-bottom: unset;
  }

  .product-box img {
    max-height: 300px;
  }

  .brands-product-display a:visited,
  .brands-product-display a:link {
    font-size: 20px;
  }

  .brands-filter {
    width: 30%;
    display: flex;
    position: unset;
    align-items: center;
    background-color: transparent;
    padding-left: 0;
  }
}