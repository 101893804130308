
.container-product-detail {
  display: flex;
  flex-direction:row;
  max-width: 100%;
  justify-content: center;
}

.container-product-detail-mobile {
  display: flex;
  flex-direction:row;
  max-width: 100%;
  justify-content: center;
}

.container p{
  font-size: 12px;
}

.headings .active {
  z-index: 10;
  background-color: #fafafa;
}

.product-detail-image img {
  width: 90%;
  height: auto;
}

.product-top-mobile {
  display: flex;
  flex-direction: row;
}

.images-delipet {
  display: none;
  flex-direction: column;
}

.delipet-logo img{
  margin-left:100px;
  margin-top:100px;
  max-height:120px;
}

.delipet-logo-mobile img{
  max-height: 100px;
}

.delipet-product {
  margin-top:40px;
}

.delipet-product img{
  width: 410px;
}

.box-container-one {
  display: none;
}

.box-container-two{
  display: flex;
  flex-direction: column;
  box-shadow: 10px 20px 70px -30px;
  border-radius: 10px;
  padding: 30px 30px 15px 30px;
  position: relative;
  margin-top: 45px;
  background-color: #fafafa;
  z-index: 15;
  border-top-left-radius: 0px;
  width: 230px;
  min-height: 600px;
}

.box-container-two-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 220px;
}

.headings > div {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 75px;
  padding: 0px 10px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  background-color: #FAFAFA;
  box-shadow: 2px -2px 8px 1px rgba(222,222,222,0.7);
}

.headings .portions-heading {
  z-index: 5;
  left: 85px;
}

.headings .instructions-heading {
  left: 175px;
  width: 90px;
}

.headings{
  display: flex;
  flex-direction: row;
  float: left;
  background-color: transparent;
  position: relative;
}

.headings h3{
  font-size: 14px;
}

.product-title h1{
  text-transform: uppercase;
  font-size: 28px;
  margin:0;
}
.product-title h2{
  text-transform: uppercase;
  font-weight: 500;
  font-size: 28px;
  margin:0;
}

.product-title hr {
  margin-left: 0;
  width: 70px;
  margin-top: 15px;
  border: 1px solid #DEDFDE;
  border-bottom-width: 0px;
}

.title-table-asterisc {
  width: 230px;
  
}

.titulo-tabla p {
  font-weight: 600;
}
.ingredients-table{
  border-collapse: collapse;
  border: 1px solid;
  
}

.ingredients-table table tr,td{
  border-collapse: collapse;
  border: 1px solid;
  padding:5px;
  font-size: 14px;
  width: 100px;
  height: 20px;
}

.row-2 {
  display:flex;
  flex-direction:row;
  align-items: center;
}
.asterisc p {
  margin:2px;
  font-size:10px;
}

.three-icons{
  padding-top: 40px;
  display:flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}

.three-icons div {
  display: flex;
  align-items: center;
  padding:10px;
  justify-content: center;
  flex-direction: column;
}

.three-icons div p {
  font-size: 8px;
  text-align: center;
  width: 80px;
  font-weight: 600;
}

.three-icons .icon1 p {
  width: 80px;
}

.three-icons div img{
  width:80px;
}


.ingredients{
  margin-top: 70px;
}

.ingredients h1{
  font-size: 12px;
  font-weight: 600;
}

.ingredients p{
  font-size: 12px;
  padding-right: 50px;
}

.back-brands {
  text-align: center;
}

.back-brands button{
  margin:50px;
  padding:15px 25px;
  box-shadow: 2px 2px 5px 5px rgba(222,222,222,0.7);
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  background-color:#ffae3b;
  border:none;
  cursor: pointer;
  color: #315856;
}

.instructions-list-product p {
  font-size: 14px;
}


.portions-table img{
  width:250px;
}

.instructions-list-product p span {
  font-weight: 600;
}

.blue-balls img {
  width: 100%;
}

.blue-balls {
  padding-right: 30px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.blue-balls p {
  font-size: 10px;
  margin-bottom: -20px;
}

.portions-table-footer p {
  margin: 0;
  font-size: 10px;
}

.portions-recomendation h3 {
  font-size: 14px;
  margin-bottom: 5px;
}

.portions-recomendation p {
  font-size: 14px;
  margin-bottom: 0;
}

@media only screen and (min-width: 400px) {
  .box-container-two {
    width: 300px;
  }

  .headings > div {
    width: 100px;
  }

  .headings .portions-heading {
    left: 110px;
  }
  
  .headings .instructions-heading {
    left: 220px;
    width: 110px;
  }
}

@media only screen and (min-width: 580px) {
  .box-container-two {
    width: 480px;
  }

  .images-delipet {
    display:flex;
  }

  .portions-table img{
    width:500px;
  }
}

@media only screen and (min-width: 768px) {
  .box-container-two {
    width: 600px;
  }

  .headings > div {
    width: 100px;
  }

  .headings .portions-heading {
    left: 110px;
  }
  
  .headings .instructions-heading {
    left: 220px;
    width: 110px;
  }
}

@media only screen and (min-width: 1150px) {
  .box-container-two {
    width: 600px;
  }

  .headings > div {
    width: 140px;
  }

  .headings .portions-heading {
    left: 140px;
  }
  
  .headings .instructions-heading {
    left: 280px;
    width: 140px;
  }

  .box-container-one {
    display: flex;
  }

  .images-delipet {
    display:flex;
  }

  .container-product-detail {
    justify-content: space-between;
  }

  .three-icons{
    width: 60%;
  }
}