.footer-container {
  min-height: 400px;
  background-color: #0A4A54;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  color: white;
  height: 100%;
}

.info {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 400px;
  font-size: 12px;
  padding-bottom: 20px;
}

.info p {
  margin: 0
}

.info span {
  font-weight: 600;
}

.extras {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 20px;
  font-size: 12px;
  text-align: center;
}

.extras p {
  margin: 0
}

.logo-footer img {
  width: 100px;
  padding: 0px 15px;
}

@media only screen and (min-width: 768px) {
  .footer-container {
    min-height: 200px;
  }

  .footer {
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    max-height: 70%;
  }

  .info {
    padding-right: 0px;
    padding-bottom: 0px;
    font-size: 14px;
  }

  .extras {
    padding-left: 0;
    padding-right: 25px;
    padding-top: 0px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1024px) {
  .info {
    font-size: unset;
  }

  .extras {
    font-size: unset;
  }
}